import { defineStore } from "pinia";
import { useQuery } from "@vue/apollo-composable";
import { GET_OVERVIEW_MAP } from "~/graphql/GetOverviewMap";

export const useOverview = defineStore("overview", () => {
  // State
  const title = ref("");
  const description = ref("");
  const breadcrumb = ref("");
  const filterReset = ref(false);
  const filterResultsEmpty = ref(false);
  const mapView = ref("communities");
  const collectionFilter = ref("");

  // Get Overview Map Data
  const { result: overviewMapResult } = useQuery(GET_OVERVIEW_MAP, {
    uri: "/our-homes/communities/",
  });

  // Getters
  const tabsDescription = computed(
    () => overviewMapResult?.value?.tabsDescription ?? []
  );
  const neighborhoods = computed(
    () => overviewMapResult?.value?.neighborhoods ?? []
  );
  const models = computed(() => overviewMapResult?.value?.models ?? []);
  const homes = computed(() => overviewMapResult?.value?.homes ?? []);
  const plans = computed(() => overviewMapResult?.value?.plans ?? []);

  const mapInfo = computed(() => {
    switch (mapView.value) {
      case "model-homes":
        return {
          mapView: mapView.value,
          nodes: models?.value?.nodes?.length
            ? models.value.nodes
                .map((node) => {
                  //console.log(sqft);
                  return {
                    collection:
                      node?.modelDetails?.floorplan?.floorplanDetails?.collection,
                    listItemId: node.slug,
                    title: node.title,
                    beds: {
                      min: node?.modelDetails?.floorplan?.floorplanDetails
                        ?.bedroomsMin,
                      max: node?.modelDetails?.floorplan?.floorplanDetails
                        ?.bedroomsMax,
                    },
                    baths: {
                      min: node?.modelDetails?.floorplan?.floorplanDetails
                        ?.bathroomsMin,
                      max: node?.modelDetails?.floorplan?.floorplanDetails
                        ?.bathroomsMax,
                    },
                    sqft: node?.modelDetails?.floorplan?.floorplanDetails
                      ?.mainSqrFt,
                    latitude: node?.modelDetails?.latitude,
                    longitude: node?.modelDetails?.longitude,
                    directionsLink:
                      "https://www.google.com/maps/dir/?api=1&destination=" +
                      node?.modelDetails?.latitude +
                      "," +
                      node?.modelDetails?.longitude,
                    linkText: "Explore " + node.title,
                    link: node.uri,
                    thumbnailSrc: getImageSrc(
                      node.featuredImage?.node ??
                        node?.modelDetails?.neighborhood?.neighborhood?.thumbnail,
                      "medium"
                    ),
                    thumbnailAlt:
                      node.featuredImage?.node.altText ??
                      node.modelDetails?.neighborhood?.neighborhood?.thumbnail
                        ?.altText,
                  };
                })
                .filter((node) => {
                  if (!collectionFilter.value) return true;
                  return node.collection === collectionFilter.value;
                })
            : [],
        };

      case "communities":
        return {
          mapView: mapView.value,
          nodes: neighborhoods?.value?.nodes?.length
            ? neighborhoods.value.nodes
                .map((node) => {
                  const collections = getCollectionsByNeighborhood(
                    node.slug,
                    plans?.value?.nodes,
                    null,
                    null,
                    [node.collection?.collection]
                  );
                  return {
                    collections: collections,
                    listItemId: node.slug,
                    title: node.title,
                    subtitle: getNeighborhoodNavSubtitle(
                      node.types,
                      getRoundedPrice(node.basePrice),
                      node.neighborhood?.status
                    ),
                    latitude: node.neighborhood?.latitude,
                    longitude: node.neighborhood?.longitude,
                    directionsLink:
                      "https://www.google.com/maps/dir/?api=1&destination=" +
                      node.neighborhood?.latitude +
                      "," +
                      node.neighborhood?.longitude,
                    linkText: "Explore this community",
                    link: node.uri,
                    thumbnailSrc: getImageSrc(
                      node.neighborhood?.thumbnail,
                      "medium"
                    ),
                    thumbnailAlt: node.neighborhood?.thumbnail?.altText,
                    hideCommunity: node.neighborhood?.hideCommunity,
                  };
                })
                .filter((node) => {
                  if (!collectionFilter.value.length) return true;
                  if (typeof collectionFilter.value === 'string') {
                    return node.collections.includes(collectionFilter.value);
                  } else if (Array.isArray(collectionFilter.value)) {
                    const filterCollections = collectionFilter.value.map(kebabCase);
                    const collectionsArray = node.collections.map(kebabCase);
                    return filterCollections.some(collection => collectionsArray.includes(collection));
                  }
                  return false;
                })
            : [],
        };

      case "quick-move-in":
        return {
          mapView: mapView.value,
          nodes: homes?.value?.nodes?.length
            ? homes.value.nodes
                .map((node) => {
                  return {
                    collection:
                      node.qmiHomeDetails?.floorplan?.floorplanDetails
                        ?.collection,
                    listItemId: node.slug,
                    title: node.title,
                    plan: node.qmiHomeDetails?.floorplan?.title,
                    beds: {
                      min: node.qmiHomeDetails?.bedrooms,
                    },
                    baths: {
                      min: node.qmiHomeDetails?.bathrooms,
                    },
                    sqft: node.qmiHomeDetails?.finishedSqrFt,
                    hideOnMap: node.qmiHomeDetails?.hideOnMap,
                    latitude: node.qmiHomeDetails?.latitude,
                    longitude: node.qmiHomeDetails?.longitude,
                    directionsLink:
                      "https://www.google.com/maps/dir/?api=1&destination=" +
                      node.qmiHomeDetails?.latitude +
                      "," +
                      node.qmiHomeDetails?.longitude,
                    linkText: "Explore this home",
                    link: node.uri,
                    thumbnailSrc: getImageSrc(
                      node.featuredImage?.node ??
                        node.qmiHomeDetails?.neighborhood?.neighborhood?.thumbnail,
                      "medium"
                    ),
                    thumbnailAlt:
                      node.featuredImage?.node?.altText ??
                      node.qmiHomeDetails?.neighborhood?.neighborhood?.thumbnail
                        .altText,
                  };
                })
                .filter((node) => {
                  if (!collectionFilter.value) return true;
                  return node.collection === collectionFilter.value;
                })
            : [],
        };

      default:
        return {};
    }
  });

  return {
    breadcrumb,
    collectionFilter,
    description,
    filterReset,
    filterResultsEmpty,
    homes,
    mapInfo,
    mapView,
    models,
    neighborhoods,
    plans,
    tabsDescription,
    title,
  };
});
